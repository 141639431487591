.no-margin{
  margin:0;
}
/* Small Screen */
@media only screen and (max-width: 800px) {
  .max-width-container {
    max-width: 100%;
    margin: auto; }

  .small-max-width-container {
    max-width: 90%;
    margin: auto; }

  .small-centered-text {
    text-align: center; }

  .tiny-padding {
    padding: 2px; }

  .tiny-right-padding {
    padding-right: 2px; }

  .tiny-right-margin {
    margin-right: 2px; }

  .tiny-left-padding {
    padding-left: 2px; }

  .tiny-left-margin {
    margin-left: 2px; }

  .tiny-top-padding {
    padding-top: 2px; }

  .tiny-top-margin {
    margin-top: 2px; }

  .tiny-bottom-padding {
    padding-bottom: 2px; }

  .tiny-bottom-margin {
    margin-bottom: 2px; }

  .small-padding {
    padding: 5px; }

  .small-right-padding {
    padding-right: 5px; }

  .small-right-margin {
    margin-right: 5px; }

  .small-left-padding {
    padding-left: 5px; }

  .small-left-margin {
    margin-left: 5px; }

  .small-top-padding {
    padding-top: 5px; }

  .small-top-margin {
    margin-top: 5px; }

  .small-bottom-padding {
    padding-bottom: 5px; }

  .small-bottom-margin {
    margin-bottom: 5px; }

  .medium-padding {
    padding: 10px; }

  .medium-right-padding {
    padding-right: 10px; }

  .medium-right-margin {
    margin-right: 10px; }

  .medium-left-padding {
    padding-left: 10px; }

  .medium-left-margin {
    margin-left: 10px; }

  .medium-top-padding {
    padding-top: 10px; }

  .medium-top-margin {
    margin-top: 10px; }

  .medium-bottom-padding {
    padding-bottom: 10px; }

  .medium-bottom-margin {
    margin-bottom: 10px; }

  .large-padding {
    padding: 20px; }

  .large-right-padding {
    padding-right: 20px; }

  .large-right-margin {
    margin-right: 20px; }

  .large-left-padding {
    padding-left: 20px; }

  .large-left-margin {
    margin-left: 20px; }

  .large-top-padding {
    padding-top: 20px; }

  .large-top-margin {
    margin-top: 20px; }

  .large-bottom-padding {
    padding-bottom: 20px; }

  .large-bottom-margin {
    margin-bottom: 20px; }

  .extra-large-padding {
    padding: 40px; }

  .extra-large-right-padding {
    padding-right: 40px; }

  .extra-large-right-margin {
    margin-right: 40px; }

  .extra-large-left-padding {
    padding-left: 40px; }

  .extra-large-left-margin {
    margin-left: 40px; }

  .extra-large-top-padding {
    padding-top: 40px; }

  .extra-large-top-margin {
    margin-top: 40px; }

  .extra-large-bottom-padding {
    padding-bottom: 40px; }

  .extra-large-bottom-margin {
    margin-bottom: 40px; }
}
/* Medium Screen */
@media only screen and (min-width: 800px) {
  .max-width-container {
    max-width: 90%;
    margin: auto; }

  .small-max-width-container {
    max-width: 65%;
    margin: auto; }

  .tiny-padding {
    padding: 4px; }

  .tiny-right-padding {
    padding-right: 4px; }

  .tiny-right-margin {
    margin-right: 4px; }

  .tiny-left-padding {
    padding-left: 4px; }

  .tiny-left-margin {
    margin-left: 4px; }

  .tiny-top-padding {
    padding-top: 4px; }

  .tiny-top-margin {
    margin-top: 4px; }

  .tiny-bottom-padding {
    padding-bottom: 4px; }

  .tiny-bottom-margin {
    margin-bottom: 4px; }

  .small-padding {
    padding: 10px; }

  .small-right-padding {
    padding-right: 10px; }

  .small-right-margin {
    margin-right: 10px; }

  .small-left-padding {
    padding-left: 10px; }

  .small-left-margin {
    margin-left: 10px; }

  .small-top-padding {
    padding-top: 10px; }

  .small-top-margin {
    margin-top: 10px; }

  .small-bottom-padding {
    padding-bottom: 10px; }

  .small-bottom-margin {
    margin-bottom: 10px; }

  .medium-padding {
    padding: 20px; }

  .medium-right-padding {
    padding-right: 20px; }

  .medium-right-margin {
    margin-right: 20px; }

  .medium-left-padding {
    padding-left: 20px; }

  .medium-left-margin {
    margin-left: 20px; }

  .medium-top-padding {
    padding-top: 20px; }

  .medium-top-margin {
    margin-top: 20px; }

  .medium-bottom-padding {
    padding-bottom: 20px; }

  .medium-bottom-margin {
    margin-bottom: 20px; }

  .large-padding {
    padding: 40px; }

  .large-right-padding {
    padding-right: 40px; }

  .large-right-margin {
    margin-right: 40px; }

  .large-left-padding {
    padding-left: 40px; }

  .large-left-margin {
    margin-left: 40px; }

  .large-top-padding {
    padding-top: 40px; }

  .large-top-margin {
    margin-top: 40px; }

  .large-bottom-padding {
    padding-bottom: 40px; }

  .large-bottom-margin {
    margin-bottom: 40px; }

  .extra-large-padding {
    padding: 80px; }

  .extra-large-right-padding {
    padding-right: 80px; }

  .extra-large-right-margin {
    margin-right: 80px; }

  .extra-large-left-padding {
    padding-left: 80px; }

  .extra-large-left-margin {
    margin-left: 80px; }

  .extra-large-top-padding {
    padding-top: 80px; }

  .extra-large-top-margin {
    margin-top: 80px; }

  .extra-large-bottom-padding {
    padding-bottom: 80px; }

  .extra-large-bottom-margin {
    margin-bottom: 80px; }

}
/* Large Screen */
@media only screen and (min-width: 1600px) {

  .max-width-container {
    max-width: 1200px;
    margin: auto; }

  .small-max-width-container {
    max-width: 900px;
    margin: auto; }

  .tiny-padding {
    padding: 6px; }

  .tiny-right-padding {
    padding-right: 6px; }

  .tiny-right-margin {
    margin-right: 6px; }

  .tiny-left-padding {
    padding-left: 6px; }

  .tiny-left-margin {
    margin-left: 6px; }

  .tiny-top-padding {
    padding-top: 6px; }

  .tiny-top-margin {
    margin-top: 6px; }

  .tiny-bottom-padding {
    padding-bottom: 6px; }

  .tiny-bottom-margin {
    margin-bottom: 6px; }

  .small-padding {
    padding: 15px; }

  .small-right-padding {
    padding-right: 15px; }

  .small-right-margin {
    margin-right: 15px; }

  .small-left-padding {
    padding-left: 15px; }

  .small-left-margin {
    margin-left: 15px; }

  .small-top-padding {
    padding-top: 15px; }

  .small-top-margin {
    margin-top: 15px; }

  .small-bottom-padding {
    padding-bottom: 15px; }

  .small-bottom-margin {
    margin-bottom: 15px; }

  .medium-padding {
    padding: 30px; }

  .medium-right-padding {
    padding-right: 30px; }

  .medium-right-margin {
    margin-right: 30px; }

  .medium-left-padding {
    padding-left: 30px; }

  .medium-left-margin {
    margin-left: 30px; }

  .medium-top-padding {
    padding-top: 30px; }

  .medium-top-margin {
    margin-top: 30px; }

  .medium-bottom-padding {
    padding-bottom: 30px; }

  .medium-bottom-margin {
    margin-bottom: 30px; }

  .large-padding {
    padding: 60px; }

  .large-right-padding {
    padding-right: 60px; }

  .large-right-margin {
    margin-right: 60px; }

  .large-left-padding {
    padding-left: 60px; }

  .large-left-margin {
    margin-left: 60px; }

  .large-top-padding {
    padding-top: 60px; }

  .large-top-margin {
    margin-top: 60px; }

  .large-bottom-padding {
    padding-bottom: 60px; }

  .large-bottom-margin {
    margin-bottom: 60px; }

  .extra-large-padding {
    padding: 120px; }

  .extra-large-right-padding {
    padding-right: 120px; }

  .extra-large-right-margin {
    margin-right: 120px; }

  .extra-large-left-padding {
    padding-left: 120px; }

  .extra-large-left-margin {
    margin-left: 120px; }

  .extra-large-top-padding {
    padding-top: 120px; }

  .extra-large-top-margin {
    margin-top: 120px; }

  .extra-large-bottom-padding {
    padding-bottom: 120px; }

  .extra-large-bottom-margin {
    margin-bottom: 120px; } }
/* Huge Screen */
@media only screen and (min-width: 1400px) {
  .small-max-width-container {
    max-width: 1000px;
    margin: auto; }

  .max-width-container {
    max-width: 1400px;
    margin: auto; }

  .tiny-padding {
    padding: 8px; }

  .tiny-right-padding {
    padding-right: 8px; }

  .tiny-right-margin {
    margin-right: 8px; }

  .tiny-left-padding {
    padding-left: 8px; }

  .tiny-left-margin {
    margin-left: 8px; }

  .tiny-top-padding {
    padding-top: 8px; }

  .tiny-top-margin {
    margin-top: 8px; }

  .tiny-bottom-padding {
    padding-bottom: 8px; }

  .tiny-bottom-margin {
    margin-bottom: 8px; }

  .small-padding {
    padding: 20px; }

  .small-right-padding {
    padding-right: 20px; }

  .small-right-margin {
    margin-right: 20px; }

  .small-left-padding {
    padding-left: 20px; }

  .small-left-margin {
    margin-left: 20px; }

  .small-top-padding {
    padding-top: 20px; }

  .small-top-margin {
    margin-top: 20px; }

  .small-bottom-padding {
    padding-bottom: 20px; }

  .small-bottom-margin {
    margin-bottom: 20px; }

  .medium-padding {
    padding: 40px; }

  .medium-right-padding {
    padding-right: 40px; }

  .medium-right-margin {
    margin-right: 40px; }

  .medium-left-padding {
    padding-left: 40px; }

  .medium-left-margin {
    margin-left: 40px; }

  .medium-top-padding {
    padding-top: 40px; }

  .medium-top-margin {
    margin-top: 40px; }

  .medium-bottom-padding {
    padding-bottom: 40px; }

  .medium-bottom-margin {
    margin-bottom: 40px; }

  .large-padding {
    padding: 80px; }

  .large-right-padding {
    padding-right: 80px; }

  .large-right-margin {
    margin-right: 80px; }

  .large-left-padding {
    padding-left: 80px; }

  .large-left-margin {
    margin-left: 80px; }

  .large-top-padding {
    padding-top: 80px; }

  .large-top-margin {
    margin-top: 80px; }

  .large-bottom-padding {
    padding-bottom: 80px; }

  .large-bottom-margin {
    margin-bottom: 80px; }

  .extra-large-padding {
    padding: 160px; }

  .extra-large-right-padding {
    padding-right: 160px; }

  .extra-large-right-margin {
    margin-right: 160px; }

  .extra-large-left-padding {
    padding-left: 160px; }

  .extra-large-left-margin {
    margin-left: 160px; }

  .extra-large-top-padding {
    padding-top: 160px; }

  .extra-large-top-margin {
    margin-top: 160px; }

  .extra-large-bottom-padding {
    padding-bottom: 160px; }

  .extra-large-bottom-margin {
    margin-bottom: 160px; } }
/* Massive Screen */
@media only screen and (min-width: 1800px) {
  .small-max-width-container {
    max-width: 1200px;
    margin: auto; }

  .max-width-container {
    max-width: 1800px;
    margin: auto; }

  .tiny-padding {
    padding: 10px; }

  .tiny-right-padding {
    padding-right: 10px; }

  .tiny-right-margin {
    margin-right: 10px; }

  .tiny-left-padding {
    padding-left: 10px; }

  .tiny-left-margin {
    margin-left: 10px; }

  .tiny-top-padding {
    padding-top: 10px; }

  .tiny-top-margin {
    margin-top: 10px; }

  .tiny-bottom-padding {
    padding-bottom: 10px; }

  .tiny-bottom-margin {
    margin-bottom: 10px; }

  .small-padding {
    padding: 25px; }

  .small-right-padding {
    padding-right: 25px; }

  .small-right-margin {
    margin-right: 25px; }

  .small-left-padding {
    padding-left: 25px; }

  .small-left-margin {
    margin-left: 25px; }

  .small-top-padding {
    padding-top: 25px; }

  .small-top-margin {
    margin-top: 25px; }

  .small-bottom-padding {
    padding-bottom: 25px; }

  .small-bottom-margin {
    margin-bottom: 25px; }

  .medium-padding {
    padding: 50px; }

  .medium-right-padding {
    padding-right: 50px; }

  .medium-right-margin {
    margin-right: 50px; }

  .medium-left-padding {
    padding-left: 50px; }

  .medium-left-margin {
    margin-left: 50px; }

  .medium-top-padding {
    padding-top: 50px; }

  .medium-top-margin {
    margin-top: 50px; }

  .medium-bottom-padding {
    padding-bottom: 50px; }

  .medium-bottom-margin {
    margin-bottom: 50px; }

  .large-padding {
    padding: 100px; }

  .large-right-padding {
    padding-right: 100px; }

  .large-right-margin {
    margin-right: 100px; }

  .large-left-padding {
    padding-left: 100px; }

  .large-left-margin {
    margin-left: 100px; }

  .large-top-padding {
    padding-top: 100px; }

  .large-top-margin {
    margin-top: 100px; }

  .large-bottom-padding {
    padding-bottom: 100px; }

  .large-bottom-margin {
    margin-bottom: 100px; }

  .extra-large-padding {
    padding: 200px; }

  .extra-large-right-padding {
    padding-right: 200px; }

  .extra-large-right-margin {
    margin-right: 200px; }

  .extra-large-left-padding {
    padding-left: 200px; }

  .extra-large-left-margin {
    margin-left: 200px; }

  .extra-large-top-padding {
    padding-top: 200px; }

  .extra-large-top-margin {
    margin-top: 200px; }

  .extra-large-bottom-padding {
    padding-bottom: 200px; }

  .extra-large-bottom-margin {
    margin-bottom: 200px; }
  }
