* {
    box-sizing: border-box;
}
.border-bottom{
	border-bottom: 1px solid rgba(0,0,0,.1);
}
html, body, #root{
  height:100%;
  width: 100%;
  margin:0;
  overflow:hidden;
  font-family:"myriad-pro";
}
.button {
	margin: 10px;
	display: table;
	text-align: center;
	padding: 10px;
	padding-left: 60px;
	padding-right: 60px;
	border-radius: 2px;
	font-weight: bold;
	font-size: 20px;
	background-color: black;
	color: white;
	cursor: pointer;
 }
.wrapper{
  display: flex;
  height:100%;
  width: 100%;
}
#content{
  width:100%;
  overflow-y: scroll;
}
/*Header */
#header nav a{
  padding:10px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: block;
  text-decoration: none;
  color:#000;
  border-bottom: 1px solid rgba(0,0,0,.05);
}
.back-button{
	height:40px;
  width: 40px;
	margin-right: 10px;
}
.nav-header .back-button{
  position: absolute;
  left: 10;
  top:10;
}
#header{
  width: 250px;
  height: 100%;
  transition:all 1s ease-out;
  -webkit-transition:all 1s ease-out;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, .05);
  z-index: 100;
	position: relative;
}
.white-background{
  background-color:#fff;
}
.dark-grey-background{
  background-color:#262c32;
}
.light-grey-background{
  background-color:#f6f9fc;
}
.medium-grey-background{
  background-color:#3e4853;
}
.primary-background{
  background-color:#0072ad;
}
.primary-color{
  color:#0072ad;
}
.black-color{
	color:#000;
}
.full-width{
  width: 100%;
}
.half-width{
  width: 50%;
}
.quarter-width{
  width: 25%;
}
.three-quarter-width{
  width: 75%;
}
.centered-text{
  text-align: center;
}
.icon-100{
  height:100px;
  width: 100px;
}
.center-position{
  margin:auto;
}
.left-text{
  text-align: left;
}
.right-text{
  text-align: right;
}
a{
  text-decoration: none;
}
input[type=text],input[type=password],textarea{
	height:50px;
  width: 100%;
  border: 0px;
  font-size: 14px;
}
input[type=text].category-search{
	padding: 5px;
	width: 100%;
	border: 0px;
	font-size: 14px;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-top: 15px;
}

.autosuggest-container{
	position: relative;
}

.autosuggest-container div{
	width: 100%;
}
.autosuggest-container div.autosuggest-menu{
	position: absolute;
  background-color: #fff;
  padding: 20px;
  line-height: 2;
	z-index: 1000;
	box-shadow: 0px 10px 20px rgba(0,0,0,.05);
}
.autosuggest-container .pill, .pill{
	display: inline-block;
	width: auto;
	border-radius: 20px;
	margin-top: 10px;
	cursor: pointer;
}

.react-tabs ul{
	margin: 0;
	padding:0;
	margin-top: 20px;
	line-height:2;
}
.react-tabs li{
	list-style: none;
	display: inline;
	padding:10px;
	padding-left:40px;
	padding-right:40px;
	margin-right:10px;
	font-size: 16px;
	opacity: .5;
}
.react-tabs .react-tabs__tab--selected{
	background-color: #fff;
	opacity: 1;
}

.react-tabs__tab-panel{
	background-color: #fff;
}

input[type=text].outline, textarea.outline, .outline{
	border: 1px solid rgba(0,0,0,.05);
}
select{
	width: 100%;
	height: 50px;
	font-size: 14px;
	background-color: #fff;
	border: 0;
}
.pill{
	padding:10px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #000;
	color:#fff;
	margin-right: 10px;
	display: inline-block;
}
.Collapsible{
	padding:1%;
}

.feature-collapse .Collapsible{
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.1);
		margin-bottom: 10px;

}

.feature-collapse .Collapsible span{
    width: 100%;
		display: block;
}

.uppercase{
	text-transform:uppercase;
}
.back-button{
	border-radius: 50%;
	background-color: #262c32;
	margin-bottom: 20px;
	margin-top: 10px;
	padding:3px;
}
.background-image-fill{
	background-size: cover;
	background-position: center;
}
.background-image-fit{
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
.red{
	color:#db4437;
}
.red-background{
	background-color:#db4437;
}
.image-delete-button{
	position: absolute;
	top: 0;
	right: 0;
	height: 20px;
	width: 20px;
	z-index: 100;
}
.circle{
	border-radius: 50%
}
.list-tile{
	background-color: #fff;
	margin-right: 20px;
	min-width: 200px;
	text-align: center;
}
.hidden{
	display: none;
}
.icon-20{
	height:20px;
	width:20px;
}
