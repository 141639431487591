/*grid */
.row {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (max-width: 800px) {
  .row .small-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }

  .row .small-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }

  .row .small-3 {
    flex-basis: 25%;
    max-width: 25%; }

  .row .small-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }

  .row .small-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }

  .row .small-6 {
    flex-basis: 50%;
    max-width: 50%; }

  .row .small-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }

  .row .small-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }

  .row .small-9 {
    flex-basis: 75%;
    max-width: 75%; }

  .row .small-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }

  .row .small-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }

  .row .small-12 {
    flex-basis: 100%;
    max-width: 100%; }

 }
/* Medium Screen */
@media only screen and (min-width: 800px) {

  .row .medium-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }

  .row .medium-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }

  .row .medium-3 {
    flex-basis: 25%;
    max-width: 25%; }

  .row .medium-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }

  .row .medium-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }

  .row .medium-6 {
    flex-basis: 50%;
    max-width: 50%; }

  .row .medium-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }

  .row .medium-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }

  .row .medium-9 {
    flex-basis: 75%;
    max-width: 75%; }

  .row .medium-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }

  .row .medium-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }

  .row .medium-12 {
    flex-basis: 100%;
    max-width: 100%; }
}
/* Large Screen */
@media only screen and (min-width: 1600px) {

  .row .large-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }

  .row .large-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }

  .row .large-3 {
    flex-basis: 25%;
    max-width: 25%; }

  .row .large-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }

  .row .large-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }

  .row .large-6 {
    flex-basis: 50%;
    max-width: 50%; }

  .row .large-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }

  .row .large-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }

  .row .large-9 {
    flex-basis: 75%;
    max-width: 75%; }

  .row .large-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }

  .row .large-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }

  .row .large-12 {
    flex-basis: 100%;
    max-width: 100%; }

}
